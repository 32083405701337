import { all, takeLatest, put, call, select } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import { createBrowserHistory } from 'history';
import {
  ADD_EQUIPMENT_REQUEST,
  ADD_EQUIPMENT_SUCCESS,
  ADD_EQUIPMENT_ERROR,
  GET_USERS_SUCCESS,
  GET_USERS_REQUEST,
  ASSIGN_TECH_REQUEST_SUCCESS,
  MS_REBATE_VERIFY_SIGNED_ERROR,
  ASSIGN_TECH_REQUEST,
  ASSIGN_TECH_REQUEST_ERROR,
  GET_EQUIPMENTS_REQUEST,
  GET_EQUIPMENTS_REQUEST_SUCCESS,
  SET_EQUIPMENT_SET_REQUEST,
  SET_EQUIPMENT_VERIFY_VISIT_SET_REQUEST,
  REBATE_VERIFY_SIGNED_REQUEST,
  REBATE_PRINT_SET_REQUEST,
  REBATE_PRINT_SET_REQUEST_SUCCESS,
  REBATE_SUBMIT_REQUEST,
  REBATE_RECEIVE_REQUEST,
  REBATE_RECEIVE_REQUEST_ERROR,
  REBATE_RECEIVE_REQUEST_SUCCESS,
  REBATE_VERIFY_SIGNED_ERROR,
  BATCH_REBATE_PRINT_SET_REQUEST,
  BATCH_REBATE_PRINT_SET_REQUEST_SUCCESS,
  ADD_MAINTENANCE_EQUIPMENT_REQUEST,
  ADD_MAINTENANCE_EQUIPMENT_ERROR,
  ADD_MAINTENANCE_EQUIPMENT_SUCCESS,
  ASSIGN_MS_TECH_REQUEST,
  ASSIGN_MS_TECH_REQUEST_SUCCESS,
  ASSIGN_MS_TECH_REQUEST_ERROR,
  GET_MS_EQUIPMENTS_REQUEST,
  GET_MS_EQUIPMENTS_REQUEST_SUCCESS,
  SET_MS_EQUIPMENT_VERIFY_VISIT_SET_REQUEST,
  MS_REBATE_VERIFY_SIGNED_REQUEST,
  REBATE_PROCESS_SET_REQUEST,
  BATCH_REBATE_PROCESS_SET_REQUEST_SUCCESS,
  REBATE_PROCESS_SET_REQUEST_SUCCESS,
  EDIT_MAINTENANCE_EQUIPMENT_SUCCESS,
  DELETE_MAINTENANCE_EQUIPMENT_SUCCESS,
  EDIT_EQUIPMENT_REQUEST_SUCCESS,
  BATCH_REBATE_PROCESS_SET_REQUEST, REBATE_PROCESS_REQUEST, SET_MS_EQUIPMENT_SET_REQUEST, MREBATE_PRINT_SET_REQUEST, MREBATE_PRINT_SET_REQUEST_SUCCESS, MREBATE_SUBMIT_REQUEST, SET_MS_EQUIPMENT_SET_VIEW_REQUEST, SET_MS_EQUIPMENT_SET_EDIT_REQUEST, DELETE_MAINTENANCE_EQUIPMENT_REQUEST, DELETE_MAINTENANCE_EQUIPMENT_ERROR, SET_EQUIPMENT_SET_VIEW_REQUEST, REGISTER_USER_REQUEST_SUCCESS, REGISTER_USER_REQUEST_ERROR, REGISTER_USER_REQUEST, M_REBATE_RECEIVE_REQUEST, M_REBATE_RECEIVE_REQUEST_ERROR, M_REBATE_RECEIVE_REQUEST_SUCCESS, BATCH_M_REBATE_PROCESS_SET_REQUEST_SUCCESS, M_REBATE_PROCESS_SET_REQUEST_SUCCESS, M_REBATE_PROCESS_SET_REQUEST, BATCH_M_REBATE_PROCESS_SET_REQUEST, M_REBATE_PROCESS_REQUEST, SET_EQUIPMENT_SET_EDIT_REQUEST, DELETE_EQUIPMENT_REQUEST, DELETE_EQUIPMENT_REQUEST_SUCCESS, DELETE_EQUIPMENT_REQUEST_ERROR, REPORT_GET_REQUEST, REPORT_GET_REQUEST_SUCCESS
} from './constants';

import { request } from '../../utils/http';

function postNewEquipment(data) {
  return request.post('/api/v1/equipmentrebate/', data);
}

function getReports() {
  return request.get('/api/v1/report/get/');
}

function editNewEquipment(data) {
  return request.patch('/api/v1/equipmentrebate/' + data.id + "/", data);
}

function assignNewEquipment(data) {
  return request.patch('/api/v1/equipmentrebate/' + data.id + "/", data);
}

function submitNewEquipmentPrint({ currentEquipment, accessToken }) {
  request.defaults.headers.common['Authorization'] = "Token " + accessToken;
  return request.get('api/v1/create-all-pdf/send_email/?rebate_id=' + currentEquipment);
}

function submitNewEquipmentProcess({ id, email }) {
  console.log("submitNewEquipmentProcess::", id);
  //return request.patch('/api/v1/equipmentrebate-process/' + currentEquipment.id + "/", { email: email });
  return request.get('/api/v1/equipmentrebate-process-all/send_email/?rebate_id=' + id + "&email=" + email);
}

function receiveRebateRequest(data) {
  return request.patch('/api/v1/equipmentrebate/' + data.id + "/", { status: "recived" });
}

function signedEquipment(data) {

  const bodyFormData = new FormData()
  bodyFormData.append("home_owner_signature", data.home_owner_signature,);

  bodyFormData.append("technician_signature", data.technician_signature);

  request.defaults.headers.common["Content-Type"] = "multipart/form-data";
  request.defaults.timeout = 50000;
  return request.patch('/api/v1/equipmentrebate-verified/' + data.id + "/", data);
}

function getUsersRequest() {
  const res = request.get('/api/v1/users/');
  //There is no reset password endpoint in backend, it's just a fake url
  return res;
}

function getEquipmentRequest({ accessToken }) {
  request.defaults.headers.common['Authorization'] = "Token " + accessToken;
  const res = request.get('/api/v1/equipmentrebate/');
  //There is no reset password endpoint in backend, it's just a fake url
  return res;
}

function* handleNewEquipment(action) {
  try {
    const { history } = action.data;

    if (action.data.id) {
      const { status, data } = yield call(editNewEquipment, action.data);
      if (status === 200) {
        yield put({ type: EDIT_EQUIPMENT_REQUEST_SUCCESS, response: data });
        // you can change the navigate for navigateAndResetStack to go to a protected
        // route
        //NavigationService.replace('ContratorManageEquipment');
        toast.success('Equipment Rebate Saved..');
        history.push('/main/c-manage-e-all');
      } else {
        yield put({ type: ADD_EQUIPMENT_ERROR, error: 'Unknown Error' });
        toast.error('Error in saving Rebate..');
      }
    } else {
      const { status, data } = yield call(postNewEquipment, action.data);
      if (status === 201) {
        yield put({ type: ADD_EQUIPMENT_SUCCESS, response: data });
        // you can change the navigate for navigateAndResetStack to go to a protected
        // route
        //NavigationService.replace('AssignTech');
        toast.success('New Rebate Saved..');
        history.push('/main/assign-tech');

      } else {
        yield put({ type: ADD_EQUIPMENT_ERROR, error: 'Unknown Error' });
        toast.error('Error in saving Rebate..');
      }
    }
  } catch (error) {
    console.log(error)
    // todo add errors with similar structure in backend
    yield put({ type: ADD_EQUIPMENT_ERROR, error: 'Unknown Error' });
    //Toast.show('Error in saving new Rebate..', //Toast.LONG);
  }
}


function* getUsers(action) {
  try {
    const { status, data } = yield call(getUsersRequest);
    if (status === 200) {
      yield put({ type: GET_USERS_SUCCESS, data: data });
    }
  } catch (error) {
    console.log("getUsers", error);
  }
}

function* getEquipments(action) {
  try {
    const accessToken = yield select(state => state.EmailAuth.accessToken);
    const { status, data } = yield call(getEquipmentRequest, { accessToken });
    if (status === 200) {
      yield put({ type: GET_EQUIPMENTS_REQUEST_SUCCESS, data: data });
    }
  } catch (error) {
    console.log("getUsers", error);
  }
}

function* assignTect(action) {
  try {
    const { history } = action.data;
    const { status, data } = yield call(assignNewEquipment, action.data);
    if (status === 200) {
      data.technician = action.data.technician;
      yield put({ type: ASSIGN_TECH_REQUEST_SUCCESS, response: data });
      toast.success('Techinician assigned..');
      history.push('/main/rebate-submitted');
    } else {
      yield put({ type: ASSIGN_TECH_REQUEST_ERROR, error: 'Unknown Error' });
    }
  } catch (error) {
    console.log(error)
    yield put({ type: ASSIGN_TECH_REQUEST_ERROR, error: 'Unknown Error' });
  }
}

function* setEquipmentAssignTech(action) {
  const { history } = action.data;
  yield put({ type: ADD_EQUIPMENT_SUCCESS, response: action.data });
  history.push('/main/assign-tech');
}

function* setEquipmentVerifyVisit(action) {
  yield put({ type: ADD_EQUIPMENT_SUCCESS, response: action.data });
  //NavigationService.navigate('VerifyVisit');
}

function* verifyRebateWithSigned(action) {
  try {
    const { status, data } = yield call(signedEquipment, action.data);
    console.log(status, data)
    if (status === 200) {
      //Toast.show('Visit verified..', //Toast.LONG);
      //NavigationService.navigate('VerifiedRebate');
    } else { }
  } catch (error) {
    console.log(error)
    //Toast.show("Error in saving signature.. ", //Toast.LONG);
    yield put({ type: REBATE_VERIFY_SIGNED_ERROR, errors: error });
  }
}

function* printRequest(action) {
  const { history, ids } = action.data;
  yield put({ type: REBATE_PRINT_SET_REQUEST_SUCCESS, response: ids });
  history.push('/main/email-local-e');
  //NavigationService.navigate('RebateEmail');
}

function* printBactchRequest(action) {
  yield put({ type: BATCH_REBATE_PRINT_SET_REQUEST_SUCCESS, response: action.data });
  //NavigationService.navigate('RebateEmail');
}

function* printMRebateRequest(action) {
  const { history, ids } = action.data;
  yield put({ type: MREBATE_PRINT_SET_REQUEST_SUCCESS, response: ids});
  history.push('/main/email-local-m');
}

function* processRequest(action) {
  const { history, ids } = action.data;
  yield put({ type: REBATE_PROCESS_SET_REQUEST_SUCCESS, response: ids });
  history.push('/main/email-process-e');
  //NavigationService.navigate('ProcessERebateEmail');
}

function* processBactchRequest(action) {
  const { history, ids } = action.data;
  yield put({ type: BATCH_REBATE_PROCESS_SET_REQUEST_SUCCESS, response: ids });
  history.push('/main/email-process-e');
  //NavigationService.navigate('ProcessERebateEmail');
}

function* submitRebateForPrint(action) {
  const currentEquipment = yield select(state => state.MainApp.currentEquipment);
  const accessToken = yield select(state => state.EmailAuth.accessToken);
  if (currentEquipment) {
    try {
      const { status, data } = yield call(submitNewEquipmentPrint, { currentEquipment, accessToken });
      toast.success('Equipment Rebate Sent..');
    } catch (error) {
      console.log(error);
    }
  }
}


function* submitRebateForProcess(action) {
  const { history } = action.data;
  const currentEquipment = yield select(state => state.MainApp.currentEquipment);
  if (currentEquipment) {
    try {
      const { status, data } = yield call(submitNewEquipmentProcess, { id: currentEquipment.id, email: action.data.email });
      if (status === 200) {
        toast.success('Equipment Rebate Sent for Processed..');
      } else { toast.error("Error in sending email.."); }
    } catch (error) {
      toast.error("Error in sending email..");
    }
    history.push('/main/process-e-thank');
  } else {
    const printEquipments = yield select(state => state.MainApp.printEquipments);
    if (printEquipments.length > 0) {
      let ids = ''
      for (var i = 0, len = printEquipments.length; i < len; i++) {
        ids += printEquipments[i].id;
        if ((i + 1) != len) {
          ids += ','
        }
      }
      try {
        const { status, data } = yield call(submitNewEquipmentProcess, { id: ids, email: action.data.email });
        if (status === 200) {
          toast.success('Equipment Rebates Sent for Processed..');
        } else { toast.error("Error in sending email.."); }
      }
      catch (error) {
        toast.error("Error in sending email..");
      }
      history.push('/main/process-e-thank');
    }
  }
  // if (printEquipments.length > 0) {
  //   for (let index = 0; index < printEquipments.length; index++) {
  //     const element = printEquipments[index];
  //     console.log("element for Print:: ", element)
  //     const { status, data } = yield call(submitNewEquipmentProcess, { currentEquipment: element, email: action.data.email });
  //     if (status === 200) {
  //       //Toast.show(`Equipment Rebate with id:${element.unique_rebate_id}  Sent for Processed..`, //Toast.LONG);
  //     }
  //   }
  // }
}

function* receivedRebateHandle(action) {
  try {
    const { status, data } = yield call(receiveRebateRequest, action.data);
    if (status === 200) {
      toast.success('Equipment rebate received..');
      yield put({ type: REBATE_RECEIVE_REQUEST_SUCCESS, response: data });
    } else {
      yield put({ type: REBATE_RECEIVE_REQUEST_ERROR, errors: "error" });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: REBATE_RECEIVE_REQUEST_ERROR, errors: error });
  }
}

function* setEquipmentEdit(action) {
  const { history } = action.data;
  yield put({ type: ADD_EQUIPMENT_SUCCESS, response: action.data });
  history.push('/main/edit-rebate');
  //NavigationService.navigate('EditEquipmentRebate');
}

function deleteEquipmentRequest(data) {
  return request.delete('/api/v1/equipmentrebate/' + data.id + "/");
}

function* deleteEquipment(action) {
  const { history } = action.data;
  try {
    const { status, data } = yield call(deleteEquipmentRequest, action.data);
    if (status === 204) {
      yield put({ type: DELETE_EQUIPMENT_REQUEST_SUCCESS, response: action.data });
      history.push('/main/c-manage-e-all');
      toast.success('Equipment Rebate deleted..');
    } else { }
  } catch (error) {
    console.log(error)
    toast.success('Error in deleting rebate..');
    yield put({ type: DELETE_EQUIPMENT_REQUEST_ERROR, errors: error });
  }
}

//Maintenance Plan Rebate

function submitNewMRebatePrint({ currentEquipment, accessToken }) {
  request.defaults.headers.common['Authorization'] = "Token " + accessToken;
  return request.get('api/v1/create-all-m-pdf/send_email/?rebate_id=' + currentEquipment);
}

function* submitMRebateForPrint(action) {
  const currentEquipment = yield select(state => state.MainApp.currentEquipment);
  const accessToken = yield select(state => state.EmailAuth.accessToken);
  if (currentEquipment) {
    try {
      const { status, data } = yield call(submitNewMRebatePrint, { currentEquipment, accessToken });
      if (status === 200) {
        toast.success('Maintenance Rebate Sent..');
      } else { }
    } catch (error) {
      console.log(error);
    }
  }
}

function* setMSEquipmentAssignTech(action) {
  const { history } = action.data;
  yield put({ type: ADD_MAINTENANCE_EQUIPMENT_SUCCESS, response: action.data });
  history.push('/main/m-assign-tech');
}

function postNewMaintenanceEquipment(data) {
  return request.post('/api/v1/maintenancerebate/', data);
}

function deleteNewMaintenanceEquipment(data) {
  return request.delete('/api/v1/maintenancerebate/' + data.id + "/");
}

function editNewMaintenanceEquipment(data) {
  return request.patch('/api/v1/maintenancerebate/' + data.id + "/", data);
}

function getMSEquipmentRequest({ accessToken }) {
  request.defaults.headers.common['Authorization'] = "Token " + accessToken;
  const res = request.get('/api/v1/maintenancerebate/');
  //There is no reset password endpoint in backend, it's just a fake url
  return res;
}

function assignNewMSEquipment(data) {
  return request.patch('/api/v1/maintenancerebate/' + data.id + "/", data);
}

function signedMSEquipment(data) {

  let postData = { technician_visit: data.technician_visit }
  if (data.technician_visit === '2' || data.technician_visit === 2) {
    postData = {
      home_owner_signature_2: data.home_owner_signature_2,
      technician_signature_2: data.technician_signature_2,
      technician_visit: data.technician_visit
    }
  } else {
    postData = {
      home_owner_signature: data.home_owner_signature,
      technician_signature: data.technician_signature,
      technician_visit: data.technician_visit
    }
  }

  request.defaults.headers.common["Content-Type"] = "multipart/form-data";
  request.defaults.timeout = 50000;
  return request.patch('/api/v1/maintenancerebate-verified/' + data.id + "/", postData);
}

function* handleNewMaintenanceEquipment(action) {
  try {

    const { history } = action.data;
    if (action.data.id) {
      const { status, data } = yield call(editNewMaintenanceEquipment, action.data);
      if (status === 200) {
        yield put({ type: EDIT_MAINTENANCE_EQUIPMENT_SUCCESS, response: data });
        toast.success('New Maintenance Plan Rebate Saved..');
        history.push('/main/c-manage-m-all');
      } else {
        yield put({ type: ADD_MAINTENANCE_EQUIPMENT_ERROR, error: 'Unknown Error' });
        toast.error('Error in saving new Maintenance Plan Rebate..');
      }
    } else {
      const { status, data } = yield call(postNewMaintenanceEquipment, action.data);
      if (status === 201) {
        yield put({ type: ADD_MAINTENANCE_EQUIPMENT_SUCCESS, response: data });
        toast.success('New Maintenance Plan Rebate Saved..');
        history.push('/main/m-assign-tech');
      } else {
        yield put({ type: ADD_MAINTENANCE_EQUIPMENT_ERROR, error: 'Unknown Error' });
        toast.error('Error in saving new Maintenance Plan Rebate..');
      }
    }
  } catch (error) {
    console.log(error)
    // todo add errors with similar structure in backend
    yield put({ type: ADD_MAINTENANCE_EQUIPMENT_ERROR, error: 'Unknown Error' });
    //Toast.show('Error in saving new Maintenance Plan Rebate..', //Toast.LONG);
  }
}


function* assignMSTect(action) {
  try {
    const { history } = action.data;
    const { status, data } = yield call(assignNewMSEquipment, action.data);
    console.log(status, data)
    if (status === 200) {
      data.technician = action.data.technician;
      yield put({ type: ASSIGN_MS_TECH_REQUEST_SUCCESS, response: data });
      toast.success("Techinician assigned..")
      history.push('/main/m-rebate-submitted');
    } else {
      yield put({ type: ASSIGN_MS_TECH_REQUEST_ERROR, error: 'Unknown Error' });
    }
  } catch (error) {
    console.log(error)
    yield put({ type: ASSIGN_MS_TECH_REQUEST_ERROR, error: 'Unknown Error' });
  }
}

function* getMSEquipments(action) {
  try {
    const accessToken = yield select(state => state.EmailAuth.accessToken);
    const { status, data } = yield call(getMSEquipmentRequest, { accessToken });
    if (status === 200) {
      yield put({ type: GET_MS_EQUIPMENTS_REQUEST_SUCCESS, data: data });
    }
  } catch (error) {
    console.log("getUsers", error);
  }
}

function* setMSEquipmentVerifyVisit(action) {
  yield put({ type: ADD_MAINTENANCE_EQUIPMENT_SUCCESS, response: action.data });
  //NavigationService.navigate('VerifyMSVisit');
}

function* verifyMSRebateWithSigned(action) {
  try {
    const { status, data } = yield call(signedMSEquipment, action.data);
    yield call(assignNewMSEquipment, { id: data.id, technician_assign_id: '' });
    //console.log(status, data)
    if (status === 200) {
      //Toast.show('Visit verified..', //Toast.LONG);
      //NavigationService.replace('VerifiedMSRebate');
    } else { }
  } catch (error) {
    console.log(error)
    //Toast.show("Error in saving signature.. ", //Toast.LONG);
    yield put({ type: MS_REBATE_VERIFY_SIGNED_ERROR, errors: error });
  }
}

function* setEquipmentView(action) {
  const { history } = action.data;
  yield put({ type: ADD_EQUIPMENT_SUCCESS, response: action.data });
  history.push('/main/view-e-rebate');
}

function* setMSEquipmentView(action) {
  const { history } = action.data;
  yield put({ type: ADD_MAINTENANCE_EQUIPMENT_SUCCESS, response: action.data });
  history.push('/main/view-m-rebate');
}

function* setMSEquipmentEdit(action) {
  const { history } = action.data;
  yield put({ type: ADD_MAINTENANCE_EQUIPMENT_SUCCESS, response: action.data });
  history.push('/main/edit-m-rebate');
}

function* deleteMSEquipment(action) {
  
  const { history } = action.data;
  try {
    const { status, data } = yield call(deleteNewMaintenanceEquipment, action.data);
    if (status === 204) {
      yield put({ type: DELETE_MAINTENANCE_EQUIPMENT_SUCCESS, response: action.data });
      history.push('/main/c-manage-e-all');
      toast.success('Maintenance Rebate deleted..');
    } else { }
  } catch (error) {
    console.log(error)
    toast.success('Error in deleting rebate..');
    yield put({ type: DELETE_MAINTENANCE_EQUIPMENT_ERROR, errors: error });
  }

}

function receiveMRebateRequest(data) {
  return request.patch('/api/v1/maintenancerebate/' + data.id + "/", { status: "recived" });
}

function* receivedMRebateHandle(action) {
  try {
    const { status, data } = yield call(receiveMRebateRequest, action.data);
    if (status === 200) {
      toast.success('Maintenance rebate received..');
      yield put({ type: M_REBATE_RECEIVE_REQUEST_SUCCESS, response: data });
    } else {
      yield put({ type: M_REBATE_RECEIVE_REQUEST_ERROR, errors: "error" });
    }
  } catch (error) {
    console.log(error);
    yield put({ type: M_REBATE_RECEIVE_REQUEST_ERROR, errors: error });
  }
}

//ADMIN WORK
function postNewUser(data) {
  return request.post('/api/v1/signup/', data);
}

function* registerUser(action) {
  try {
    const { status, data } = yield call(postNewUser, action.data);
    console.log(status, data)
    if (status === 201) {
      yield put({ type: REGISTER_USER_REQUEST_SUCCESS, response: data });
      toast.success('New User Registered.');
    } else {
      yield put({ type: REGISTER_USER_REQUEST_ERROR, error: 'Unknown Error' });
      toast.error('Error in New User Registeration.');
    }
  } catch (error) {
    yield put({ type: REGISTER_USER_REQUEST_ERROR, error: error });
    if (error.response && error.response.data && error.response.data.email) {
      toast.error('A user is already registered with this e-mail address.');
    } else {
      toast.error('Error in New User Registeration.');
    }
    console.log("registerUser::: ", error)
  }
}


function* processMRequest(action) {
  const { history, ids } = action.data;
  yield put({ type: M_REBATE_PROCESS_SET_REQUEST_SUCCESS, response: ids });
  history.push('/main/email-process-m');
}

function* processMBactchRequest(action) {
  const { history, ids } = action.data;
  yield put({ type: BATCH_M_REBATE_PROCESS_SET_REQUEST_SUCCESS, response: ids });
  history.push('/main/email-process-m');
}

function submitNewMaintenanceProcess({ id, email }) {
  console.log("submitNewMaintenanceProcess::", id);
  return request.get('/api/v1/maintenancerebate-process-all/send_email/?rebate_id=' + id + "&email=" + email);
}

function* submitMRebateForProcess(action) {
  const { history } = action.data;
  const currentEquipment = yield select(state => state.MainApp.currentMSEquipment);
  if (currentEquipment) {
    try {
      const { status, data } = yield call(submitNewMaintenanceProcess, { id: currentEquipment.id, email: action.data.email });
      if (status === 200) {
        toast.success('Maintenance Rebate Sent for Processed..');
      } else { }
    } catch (error) {
      console.log(error);
    }
    history.push('/main/process-m-thank');
  } else {
    const printEquipments = yield select(state => state.MainApp.printEquipments);
    if (printEquipments.length > 0) {
      let ids = ''
      for (var i = 0, len = printEquipments.length; i < len; i++) {
        ids += printEquipments[i].id;
        if ((i + 1) != len) {
          ids += ','
        }
      }
      const { status, data } = yield call(submitNewMaintenanceProcess, { id: ids, email: action.data.email });
      if (status === 200) {
        toast.success('Maintenance Rebate Sent for Processed..');
      }
      history.push('/main/process-m-thank');
    }
  }
}

function* getReportRequest() {
  try {
    const accessToken = yield select(state => state.EmailAuth.accessToken);
    const { status, data } = yield call(getReports, { accessToken });
    if (status === 200) {
      yield put({ type: REPORT_GET_REQUEST_SUCCESS, data: data });
    }
  } catch (error) {
    console.log("getReportRequest", error);
  }
}


export default all([
  takeLatest(ADD_EQUIPMENT_REQUEST, handleNewEquipment),
  takeLatest(GET_USERS_REQUEST, getUsers),
  takeLatest(ASSIGN_TECH_REQUEST, assignTect),
  takeLatest(GET_EQUIPMENTS_REQUEST, getEquipments),
  takeLatest(SET_EQUIPMENT_SET_REQUEST, setEquipmentAssignTech),
  takeLatest(SET_EQUIPMENT_VERIFY_VISIT_SET_REQUEST, setEquipmentVerifyVisit),
  takeLatest(REBATE_VERIFY_SIGNED_REQUEST, verifyRebateWithSigned),
  takeLatest(REBATE_PRINT_SET_REQUEST, printRequest),
  takeLatest(BATCH_REBATE_PRINT_SET_REQUEST, printBactchRequest),
  takeLatest(REBATE_PROCESS_SET_REQUEST, processRequest),
  takeLatest(BATCH_REBATE_PROCESS_SET_REQUEST, processBactchRequest),
  takeLatest(REBATE_SUBMIT_REQUEST, submitRebateForPrint),
  takeLatest(REBATE_PROCESS_REQUEST, submitRebateForProcess),
  takeLatest(REBATE_RECEIVE_REQUEST, receivedRebateHandle),
  takeLatest(SET_EQUIPMENT_SET_EDIT_REQUEST, setEquipmentEdit),
  takeLatest(DELETE_EQUIPMENT_REQUEST, deleteEquipment),
  //Maintenacne
  takeLatest(ADD_MAINTENANCE_EQUIPMENT_REQUEST, handleNewMaintenanceEquipment),
  takeLatest(ASSIGN_MS_TECH_REQUEST, assignMSTect),
  takeLatest(GET_MS_EQUIPMENTS_REQUEST, getMSEquipments),
  takeLatest(SET_MS_EQUIPMENT_VERIFY_VISIT_SET_REQUEST, setMSEquipmentVerifyVisit),
  takeLatest(MS_REBATE_VERIFY_SIGNED_REQUEST, verifyMSRebateWithSigned),
  takeLatest(SET_MS_EQUIPMENT_SET_REQUEST, setMSEquipmentAssignTech),
  takeLatest(MREBATE_PRINT_SET_REQUEST, printMRebateRequest),
  takeLatest(MREBATE_SUBMIT_REQUEST, submitMRebateForPrint),
  takeLatest(SET_MS_EQUIPMENT_SET_VIEW_REQUEST, setMSEquipmentView),
  takeLatest(SET_MS_EQUIPMENT_SET_EDIT_REQUEST, setMSEquipmentEdit),
  takeLatest(DELETE_MAINTENANCE_EQUIPMENT_REQUEST, deleteMSEquipment),
  takeLatest(SET_EQUIPMENT_SET_VIEW_REQUEST, setEquipmentView),
  takeLatest(REGISTER_USER_REQUEST, registerUser),
  takeLatest(M_REBATE_RECEIVE_REQUEST, receivedMRebateHandle),
  takeLatest(M_REBATE_PROCESS_SET_REQUEST, processMRequest),
  takeLatest(BATCH_M_REBATE_PROCESS_SET_REQUEST, processMBactchRequest),
  takeLatest(M_REBATE_PROCESS_REQUEST, submitMRebateForProcess),

  takeLatest(REPORT_GET_REQUEST, getReportRequest),
]);
