import * as actions from "./constants";

const initialState = {
    user: null,
    accessToken: null,
    passwordResetSuccess: false,
    errors: {
        SignIn: null,
        SignUp: null,
        PasswordRecover: null
    }
};

const EmailAuthReducer = (state = initialState, action) => {
    switch (action.type) {
        case actions.EMAIL_AUTH_LOGIN_SUCCESS:
            return {
                ...state,
                accessToken: action.accessToken,
                user: action.user
            };
        case actions.EMAIL_AUTH_LOGIN_ERROR:
            return {
                ...state,
                errors: {
                    SignIn: action.error
                }
            };
        case actions.EMAIL_AUTH_PASSWORD_RESET_SUCCESS:
            return {
                ...state,
                passwordResetSuccess: true
            };
        case actions.EMAIL_AUTH_PASSWORD_RESET_ERROR:
            return {
                ...state,
                passwordResetSuccess: false
            };
        case actions.EMAIL_AUTH_PASSWORD_RECOVER_ERROR:
            return {
                ...state,
                errors: {
                    PasswordRecover: action.error
                }
            };
        case actions.EMAIL_AUTH_SIGNUP_SUCCESS:
            return {
                ...state,
                user: action.user
            };

        case actions.USER_UPDATE_REQUEST_SUCCESS:
            state.user.user.first_name = action.user.user.first_name;
            state.user.user.last_name = action.user.user.last_name;
            return {
                ...state
            };
        case actions.EMAIL_AUTH_SIGNUP_ERROR:
            return {
                ...state,
                errors: {
                    SignUp: action.error
                }
            };
        case actions.EMAIL_AUTH_LOGOUT:
            console.log("EMAIL_AUTH_LOGOUT..");
            return initialState;
        default:
            return state;
    }
};

export default EmailAuthReducer;