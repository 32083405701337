import {all, takeLatest, put, call} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {
  EMAIL_AUTH_LOGIN_REQUEST,
  EMAIL_AUTH_LOGIN_ERROR,
  EMAIL_AUTH_SIGNUP_REQUEST,
  EMAIL_AUTH_PASSWORD_RECOVER_REQUEST,
  EMAIL_AUTH_LOGIN_SUCCESS,
  EMAIL_AUTH_SIGNUP_ERROR,
  EMAIL_AUTH_SIGNUP_SUCCESS,
  EMAIL_AUTH_PASSWORD_RECOVER_SUCCESS,
  EMAIL_AUTH_PASSWORD_RECOVER_ERROR,
  FB_AUTH_LOGIN_REQUEST,
  FB_AUTH_API_REQUEST,
  FB_AUTH_API_ERROR,
  USER_UPDATE_REQUEST,
  USER_UPDATE_REQUEST_ERROR,
  USER_UPDATE_REQUEST_SUCCESS,
  EMAIL_AUTH_PASSWORD_RESET_REQUEST,
  EMAIL_AUTH_PASSWORD_RESET_SUCCESS,
  EMAIL_AUTH_PASSWORD_RESET_ERROR
} from './constants';
import {request} from '../../../utils/http';
import {AxiosResponse} from 'axios';
import {User, Place, Profile} from '../../../types';

type LoginResponse = {
  token: string
  user: User
  place: Place
  profile: Profile
  driver_profile: Profile
}

function sendLogin({email, password}) {
  return request.post<LoginResponse>('/api/v1/login/', {
    username: email,
    password
  });
}

function sendSignUp({email, password}) {
  return request.post('/api/v1/signup/', {email, password});
}

function sendPasswordRecovery(email) {
  //There is no reset password endpoint in backend, it's just a fake url
  return request.post('/api/v1/password-reset/', {email});
}


function sendPasswordReset({password, token}) {
  return request.post('/api/v1/password-reset/confirm/', {
    password,
    token
  });
}

function sendUpdate({email, first_name, last_name, password}) {
  if (password === '') {
    return request.post('/api/v1/user-update/', {
      email,
      first_name,
      last_name
    });
  }
  return request.post('/api/v1/user-update/', {
    email,
    password,
    first_name,
    last_name
  });
}

function* handleSignUp(action) {
  console.log("handleSignUp::", action)
  const {
    user: {
      email,
      password
    }
  } = action;
  try {
    const {status, data} = yield call(sendSignUp, {email, password});
    console.log(status, data);
    if (status === 201) {
      toast.success("Sucessfully registered with the new email..")
      yield put({type: EMAIL_AUTH_SIGNUP_SUCCESS, user: data});
      // you can change the navigate for navigateAndResetStack to go to a protected
      // route
    } else {
      yield put({type: EMAIL_AUTH_SIGNUP_ERROR, error: 'Unknown Error'});
      toast.error("Can't sign up with provided credentials..")
    }
  } catch (error) {
    console.log(error);
    toast.error("A user is already registered with this e-mail address.")
    // todo add errors with similar structure in backend
    yield put({type: EMAIL_AUTH_SIGNUP_ERROR, error: "Can't sign up with provided credentials"});
  }
}

function* handleLogin(action) {
  console.log("handleLogin:: ", action);
  const {
    user: {
      email,
      password
    }
  } = action;
  try {
    const {status, data}: AxiosResponse<LoginResponse> = yield call(sendLogin, {email, password});
    console.log("Response", status, data);
    if (status === 200) {
      yield put({type: EMAIL_AUTH_LOGIN_SUCCESS, accessToken: data.token, user: data});
      console.log("Login..")
    } else {
      yield put({type: EMAIL_AUTH_LOGIN_ERROR, error: 'Unknown Error'});
      toast.error("Can't sign in with provided credentials..")
    }
  } catch (error) {
    console.log(error);
    toast.error("Can't sign in with provided credentials..")
    // todo add errors with similar structure in backend
    yield put({type: EMAIL_AUTH_LOGIN_ERROR, error: "Can't sign in with provided credentials"});
  }
}

function* handlePasswordRecovery(action) {
  const {email} = action;

  try {
    const {status} = yield call(sendPasswordRecovery, email);

    if (status === 200) {
      yield put({type: EMAIL_AUTH_PASSWORD_RECOVER_SUCCESS, email});
      toast.success("Reset email sent ..");
    } else {
      yield put({type: EMAIL_AUTH_PASSWORD_RECOVER_ERROR, error: 'Unknown Error'});
    }
  } catch (error) {
    yield put({type: EMAIL_AUTH_PASSWORD_RECOVER_ERROR, error: "Can't recover password with provided email"});
    toast.error("Can't recover password with provided email ..");
  }
}

function* handleUserUpdate(action) {
  console.log("handleUserUpdate:: ", action);
  const {
    user: {
      email,
      first_name,
      last_name,
      password
    }
  } = action;
  try {
    const {status, data} = yield call(sendUpdate, {email, first_name, last_name, password});
    console.log(status, data);
    if (status === 200) {
      yield put({type: USER_UPDATE_REQUEST_SUCCESS, user: data});
      toast.success("User Updated Sucessfully..");
    } else {
      yield put({type: USER_UPDATE_REQUEST_ERROR, error: 'Unknown Error'});
      toast.error("Error in updating user..");
    }
  } catch (error) {
    // todo add errors with similar structure in backend
    yield put({type: USER_UPDATE_REQUEST_ERROR, error: "Can't sign in with provided credentials"});
    toast.error("Error in updating user..");
  }
}


function* handlePasswordReset(action) {
  const {password, token} = action;
  try {
    const {status, data} = yield call(sendPasswordReset, {password, token});
    if (status === 200) {
      yield put({
        type: EMAIL_AUTH_PASSWORD_RESET_SUCCESS,
      });
      toast.success('Password reset successfully.');
    } else {
      yield put({
        type: EMAIL_AUTH_PASSWORD_RESET_ERROR,
        error: "Can't reset password with provided password",
      });
      toast.error("Can't reset password with provided password");
    }
  } catch (error) {
    console.log("handlePasswordReset:", error)
    yield put({
      type: EMAIL_AUTH_PASSWORD_RESET_ERROR,
      error: "Can't reset password with provided password",
    });
    toast.error("Can't reset password with provided password");
  }
}

export default all([
  takeLatest(EMAIL_AUTH_LOGIN_REQUEST, handleLogin),
  takeLatest(EMAIL_AUTH_PASSWORD_RECOVER_REQUEST, handlePasswordRecovery),
  takeLatest(USER_UPDATE_REQUEST, handleUserUpdate),
  takeLatest(EMAIL_AUTH_SIGNUP_REQUEST, handleSignUp),
  takeLatest(EMAIL_AUTH_PASSWORD_RESET_REQUEST, handlePasswordReset),
]);
