import * as actions from "./constants";
import { act } from "react-test-renderer";

const initialState = {
  users: [],
  currentEquipment: null,
  currentMSEquipment: null,
  printEquipments: [],
  equipements: [],
  ms_equipements: [],
  ERRORS: null,
  SUCCESS: null,
  reports: {}
};

const MainAppReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.ADD_EQUIPMENT_SUCCESS:
      console.log(action.response);
      return {
        ...state,
        currentEquipment: action.response
      };
    case actions.ADD_MAINTENANCE_EQUIPMENT_SUCCESS:
      return {
        ...state,
        currentMSEquipment: action.response
      };
    case actions.ADD_MAINTENANCE_EQUIPMENT_SUCCESS:
      console.log(action.response);
      return {
        ...state,
        currentMSEquipment: action.response
      };
    case actions.ASSIGN_TECH_REQUEST_SUCCESS:
      return {
        ...state,
        currentEquipment: action.response
      };

    case actions.ASSIGN_MS_TECH_REQUEST_SUCCESS:
      return {
        ...state,
        currentMSEquipment: action.response
      };
    case actions.EDIT_MAINTENANCE_EQUIPMENT_SUCCESS:
      const index = state.ms_equipements
        .findIndex((obj => obj.id == action.response.id));
      state.ms_equipements[index] = action.response;
      return {
        ...state,
        ms_equipements: state.ms_equipements.slice()
      };
    case actions.EDIT_EQUIPMENT_REQUEST_SUCCESS:
      const indexE = state.equipements
        .findIndex((obj => obj.id == action.response.id));
      state.equipements[indexE] = action.response;
      return {
        ...state,
        equipements: state.equipements.slice()
      };
    case actions.DELETE_MAINTENANCE_EQUIPMENT_SUCCESS:
      const dIndex = state.ms_equipements
        .findIndex((obj => obj.id == action.response.id));
      state.ms_equipements.splice(dIndex, 1);
      return {
        ...state,
        ms_equipements: state.ms_equipements.slice()
      };
    case actions.DELETE_EQUIPMENT_REQUEST_SUCCESS:
      const dEIndex = state.equipements
        .findIndex((obj => obj.id == action.response.id));
      state.equipements.splice(dEIndex, 1);
      return {
        ...state,
        equipements: state.equipements.slice()
      };
    case actions.REBATE_RECEIVE_REQUEST_SUCCESS:
      console.log(action.response);

      const CIndex = state
        .equipements
        .findIndex((obj => obj.id == action.response.id));
      state.equipements[CIndex] = action.response;
      return {
        ...state,
        equipements: state
          .equipements
          .slice()
      };
    case actions.REBATE_PROCESS_SET_REQUEST_SUCCESS:
      return {
        ...state,
        currentEquipment: action.response,
        printEquipments: []
      };
    case actions.BATCH_REBATE_PROCESS_SET_REQUEST_SUCCESS:
      return {
        ...state,
        printEquipments: action.response,
        currentEquipment: null
      };
    case actions.M_REBATE_PROCESS_SET_REQUEST_SUCCESS:
      return {
        ...state,
        currentMSEquipment: action.response,
        printEquipments: []
      };
    case actions.BATCH_M_REBATE_PROCESS_SET_REQUEST_SUCCESS:
      return {
        ...state,
        printEquipments: action.response,
        currentMSEquipment: null
      };
    case actions.REBATE_PRINT_SET_REQUEST_SUCCESS:
      return {
        ...state,
        currentEquipment: action.response,
        printEquipments: []
      };
    case actions.MREBATE_PRINT_SET_REQUEST_SUCCESS:
      return {
        ...state,
        currentEquipment: action.response,
        printEquipments: []
      };
    case actions.BATCH_REBATE_PRINT_SET_REQUEST_SUCCESS:
      return {
        ...state,
        printEquipments: action.response,
        currentEquipment: null
      };
    case actions.GET_USERS_SUCCESS:
      return {
        ...state,
        users: action.data
      };
    case actions.GET_EQUIPMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        equipements: action.data
      };
    case actions.GET_MS_EQUIPMENTS_REQUEST_SUCCESS:
      return {
        ...state,
        ms_equipements: action.data
      };
    case actions.M_REBATE_RECEIVE_REQUEST_SUCCESS:
      console.log(action.response);
      const MIndex = state
        .ms_equipements
        .findIndex((obj => obj.id == action.response.id));
      state.ms_equipements[MIndex] = action.response;
      return {
        ...state,
        ms_equipements: state
          .ms_equipements
          .slice()
      };
    case actions.REBATE_VERIFY_SIGNED_ERROR:
      return {
        ...state,
        ERRORS: action.error
      };
    case actions.REGISTER_USER_REQUEST_ERROR:
      return {
        ...state,
        ERRORS: action.error,
        SUCCESS: null
      };
    case actions.REGISTER_USER_REQUEST_SUCCESS:
      return {
        ...state,
        SUCCESS: action.response,
        ERRORS: null
      };
    case actions.REPORT_GET_REQUEST_SUCCESS:
      return {
        ...state,
        reports: action.data
      };
    default:
      return state;
  }
};

export default MainAppReducer;