export const EMAIL_AUTH_SIGNUP_REQUEST = "EMAIL_AUTH/SIGNUP/REQUEST";
export const EMAIL_AUTH_SIGNUP_SUCCESS = "EMAIL_AUTH/SIGNUP/SUCCESS";
export const EMAIL_AUTH_SIGNUP_ERROR = "EMAIL_AUTH/SIGNUP/ERROR";

export const EMAIL_AUTH_LOGIN_REQUEST = "EMAIL_AUTH/LOGIN/REQUEST";
export const EMAIL_AUTH_LOGIN_SUCCESS = "EMAIL_AUTH/LOGIN/SUCCESS";
export const EMAIL_AUTH_LOGIN_ERROR = "EMAIL_AUTH/LOGIN/ERROR";

export const FB_AUTH_LOGIN_REQUEST = "FB_AUTH_LOGIN_REQUEST/LOGIN/REQUEST";
export const FB_AUTH_LOGIN_SUCCESS = "FB_AUTH_LOGIN_REQUEST/LOGIN/SUCCESS";
export const FB_AUTH_LOGIN_ERROR = "FB_AUTH_LOGIN_REQUEST/LOGIN/ERROR";

export const FB_AUTH_API_REQUEST = "FB_AUTH_API_REQUEST/SIGNUP/REQUEST";
export const FB_AUTH_API_SUCCESS = "FB_AUTH_API_SUCCESS/SIGNUP/SUCCESS";
export const FB_AUTH_API_ERROR = "FB_AUTH_API_ERROR/SIGNUP/ERROR";

export const USER_UPDATE_REQUEST = "EMAIL_AUTH/USER_UPDATE_REQUEST";
export const USER_UPDATE_REQUEST_SUCCESS = "EMAIL_AUTH/USER_UPDATE_REQUEST_SUCCESS";
export const USER_UPDATE_REQUEST_ERROR = "EMAIL_AUTH/USER_UPDATE_REQUEST_ERROR";

export const EMAIL_AUTH_PASSWORD_RESET_REQUEST = "EMAIL_AUTH/EMAIL_AUTH_PASSWORD_RESET_REQUEST";
export const EMAIL_AUTH_PASSWORD_RESET_SUCCESS = "EMAIL_AUTH/EMAIL_AUTH_PASSWORD_RECOVER_SUCCESS";
export const EMAIL_AUTH_PASSWORD_RESET_ERROR = "EMAIL_AUTH/EMAIL_AUTH_PASSWORD_RESET_ERROR";

// does not follow the patern REQUERST/SUCCESS/ERROR since it is syncronus action
export const EMAIL_AUTH_LOGOUT = "EMAIL_AUTH/LOGOUT";

export const EMAIL_AUTH_PASSWORD_RECOVER_REQUEST =
  "EMAIL_AUTH/PASSORD_RECOVER/REQUEST";
export const EMAIL_AUTH_PASSWORD_RECOVER_SUCCESS =
  "EMAIL_AUTH/PASSORD_RECOVER/SUCCESS";
export const EMAIL_AUTH_PASSWORD_RECOVER_ERROR =
  "EMAIL_AUTH/PASSORD_RECOVER/ERROR";