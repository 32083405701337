import { combineReducers } from "redux";

import EmailAuthReducer from '../features/Login/redux/reducers';
import MainAppReducer from '../features/redux/reducers';
/**
 * You can import more reducers here
 */


//@BlueprintReduxImportInsertion

export const combinedReducers = combineReducers({
  blank: (state, action) => {
    if (state == null) state = [];
    return state;
  },

  EmailAuth: EmailAuthReducer,
  MainApp: MainAppReducer
  //@BlueprintReduxCombineInsertion
});