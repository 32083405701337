export const ADD_EQUIPMENT_REQUEST = "MAIN_APP/ADD_EQUIPMENT_REQUEST";
export const ADD_EQUIPMENT_SUCCESS = "MAIN_APP/ADD_EQUIPMENT_SUCCESS";
export const ADD_EQUIPMENT_ERROR = "MAIN_APP/ADD_EQUIPMENT_ERROR";

export const DELETE_EQUIPMENT_REQUEST = "MAIN_APP/DELETE_EQUIPMENT_REQUEST";
export const DELETE_EQUIPMENT_REQUEST_SUCCESS = "MAIN_APP/DELETE_EQUIPMENT_REQUEST_SUCCESS";
export const DELETE_EQUIPMENT_REQUEST_ERROR = "MAIN_APP/DELETE_EQUIPMENT_REQUEST_ERROR";

export const EDIT_EQUIPMENT_REQUEST_SUCCESS = "MAIN_APP/EDIT_EQUIPMENT_REQUEST_SUCCESS";

export const ADD_MAINTENANCE_EQUIPMENT_REQUEST = "MAIN_APP/ADD_MAINTENANCE_EQUIPMENT_REQUEST";
export const ADD_MAINTENANCE_EQUIPMENT_SUCCESS = "MAIN_APP/ADD_MAINTENANCE_EQUIPMENT_SUCCESS";
export const ADD_MAINTENANCE_EQUIPMENT_ERROR = "MAIN_APP/ADD_MAINTENANCE_EQUIPMENT_ERROR";

export const EDIT_MAINTENANCE_EQUIPMENT_SUCCESS = "MAIN_APP/EDIT_MAINTENANCE_EQUIPMENT_SUCCESS";

export const DELETE_MAINTENANCE_EQUIPMENT_REQUEST = "MAIN_APP/DELETE_MAINTENANCE_EQUIPMENT_REQUEST";
export const DELETE_MAINTENANCE_EQUIPMENT_SUCCESS = "MAIN_APP/DELETE_MAINTENANCE_EQUIPMENT_SUCCESS";
export const DELETE_MAINTENANCE_EQUIPMENT_ERROR = "MAIN_APP/DELETE_MAINTENANCE_EQUIPMENT_ERROR";

export const GET_USERS_REQUEST = "MAIN_APP/GET_USERS_REQUEST";
export const GET_USERS_SUCCESS = "MAIN_APP/GET_USERS_SUCCESS";

export const ASSIGN_TECH_REQUEST = "MAIN_APP/ASSIGN_TECH_REQUEST";
export const ASSIGN_TECH_REQUEST_SUCCESS = "MAIN_APP/ASSIGN_TECH_REQUEST_SUCCESS";
export const ASSIGN_TECH_REQUEST_ERROR = "MAIN_APP/ASSIGN_TECH_REQUEST_ERROR";

export const ASSIGN_MS_TECH_REQUEST = "MAIN_APP/ASSIGN_MS_TECH_REQUEST";
export const ASSIGN_MS_TECH_REQUEST_SUCCESS = "MAIN_APP/ASSIGN_MS_TECH_REQUEST_SUCCESS";
export const ASSIGN_MS_TECH_REQUEST_ERROR = "MAIN_APP/ASSIGN_MS_TECH_REQUEST_ERROR";

export const GET_EQUIPMENTS_REQUEST = "MAIN_APP/GET_EQUIPMENTS_REQUEST";
export const GET_EQUIPMENTS_REQUEST_SUCCESS = "MAIN_APP/GET_EQUIPMENTS_REQUEST_SUCCESS";
export const GET_EQUIPMENTS_REQUEST_ERROR = "MAIN_APP/GET_EQUIPMENTS_REQUEST_ERROR";

export const SET_EQUIPMENT_SET_REQUEST = "MAIN_APP/SET_EQUIPMENT_SET_REQUEST";
export const SET_EQUIPMENT_VERIFY_VISIT_SET_REQUEST = "MAIN_APP/SET_EQUIPMENT_VERIFY_VISIT_SET_REQUEST";

export const REBATE_VERIFY_SIGNED_REQUEST = "MAIN_APP/REBATE_VERIFY_SIGN_REQUEST";
export const REBATE_VERIFY_SIGNED_SUCCESS = "MAIN_APP/REBATE_VERIFY_SIGNED_SUCCESS";
export const REBATE_VERIFY_SIGNED_ERROR = "MAIN_APP/REBATE_VERIFY_SIGNED_ERROR";

export const REBATE_PRINT_SET_REQUEST = "MAIN_APP/REBATE_PRINT_SET_REQUEST";
export const REBATE_PRINT_SET_REQUEST_SUCCESS = "MAIN_APP/REBATE_PRINT_SET_REQUEST_SUCCESS";

export const REBATE_PROCESS_SET_REQUEST = "MAIN_APP/REBATE_PROCESS_SET_REQUEST";
export const REBATE_PROCESS_SET_REQUEST_SUCCESS = "MAIN_APP/REBATE_PROCESS_SET_REQUEST_SUCCESS";

export const BATCH_REBATE_PRINT_SET_REQUEST = "MAIN_APP/BATCH_REBATE_PRINT_SET_REQUEST";
export const BATCH_REBATE_PRINT_SET_REQUEST_SUCCESS = "MAIN_APP/BATCH_REBATE_PRINT_SET_REQUEST_SUCCESS";

export const BATCH_REBATE_PROCESS_SET_REQUEST = "MAIN_APP/BATCH_REBATE_PROCESS_SET_REQUEST";
export const BATCH_REBATE_PROCESS_SET_REQUEST_SUCCESS = "MAIN_APP/BATCH_REBATE_PROCESS_SET_REQUEST_SUCCESS";

export const REBATE_SUBMIT_REQUEST = "MAIN_APP/REBATE_SUBMIT_REQUEST";
export const REBATE_SUBMIT_REQUES_SUCCESS = "MAIN_APP/REBATE_SUBMIT_REQUES_SUCCESS";
export const REBATE_SUBMIT_REQUES_ERROR = "MAIN_APP/REBATE_SUBMIT_REQUES_ERROR";

export const REBATE_PROCESS_REQUEST = "MAIN_APP/REBATE_PROCESS_REQUEST";
export const REBATE_PROCESS_REQUES_SUCCESS = "MAIN_APP/REBATE_PROCESS_REQUES_SUCCESS";
export const REBATE_PROCESS_REQUES_ERROR = "MAIN_APP/REBATE_PROCESS_REQUES_ERROR";

export const REBATE_SUBMIT_BATCH_REQUEST = "MAIN_APP/REBATE_SUBMIT_BATCH_REQUEST";
export const REBATE_SUBMIT_BATCH_REQUES_SUCCESS = "MAIN_APP/REBATE_SUBMIT_BATCH_REQUES_SUCCESS";
export const REBATE_SUBMIT_BATCH_REQUES_ERROR = "MAIN_APP/REBATE_SUBMIT_BATCH_REQUES_ERROR";

export const REBATE_RECEIVE_REQUEST = "MAIN_APP/REBATE_RECEIVE_REQUEST";
export const REBATE_RECEIVE_REQUEST_SUCCESS = "MAIN_APP/REBATE_RECEIVE_REQUEST_SUCCESS";
export const REBATE_RECEIVE_REQUEST_ERROR = "MAIN_APP/REBATE_RECEIVE_REQUEST_ERROR";

export const GET_MS_EQUIPMENTS_REQUEST = "MAIN_APP/GET_MS_EQUIPMENTS_REQUEST";
export const GET_MS_EQUIPMENTS_REQUEST_SUCCESS = "MAIN_APP/GET_MS_EQUIPMENTS_REQUEST_SUCCESS";
export const GET_MS_EQUIPMENTS_REQUEST_ERROR = "MAIN_APP/GET_MS_EQUIPMENTS_REQUEST_ERROR";

export const SET_MS_EQUIPMENT_VERIFY_VISIT_SET_REQUEST = "MAIN_APP/SET_MS_EQUIPMENT_VERIFY_VISIT_SET_REQUEST";

export const MS_REBATE_VERIFY_SIGNED_REQUEST = "MAIN_APP/MS_REBATE_VERIFY_SIGNED_REQUEST";
export const MS_REBATE_VERIFY_SIGNED_SUCCESS = "MAIN_APP/MS_REBATE_VERIFY_SIGNED_SUCCESS";
export const MS_REBATE_VERIFY_SIGNED_ERROR = "MAIN_APP/MS_REBATE_VERIFY_SIGNED_ERROR";

export const SET_EQUIPMENT_SET_EDIT_REQUEST = "MAIN_APP/SET_EQUIPMENT_SET_EDIT_REQUEST";

export const SET_MS_EQUIPMENT_SET_REQUEST = "MAIN_APP/SET_MS_EQUIPMENT_SET_REQUEST";

export const MREBATE_PRINT_SET_REQUEST = "MAIN_APP/MREBATE_PRINT_SET_REQUEST";
export const MREBATE_PRINT_SET_REQUEST_SUCCESS = "MAIN_APP/MREBATE_PRINT_SET_REQUEST_SUCCESS";

export const MREBATE_SUBMIT_REQUEST = "MAIN_APP/MREBATE_SUBMIT_REQUEST";
export const MREBATE_SUBMIT_REQUES_SUCCESS = "MAIN_APP/MREBATE_SUBMIT_REQUES_SUCCESS";
export const MREBATE_SUBMIT_REQUES_ERROR = "MAIN_APP/MREBATE_SUBMIT_REQUES_ERROR";

export const SET_MS_EQUIPMENT_SET_VIEW_REQUEST = "MAIN_APP/SET_MS_EQUIPMENT_SET_VIEW_REQUEST";

export const SET_MS_EQUIPMENT_SET_EDIT_REQUEST = "MAIN_APP/SET_MS_EQUIPMENT_SET_EDIT_REQUEST";


export const SET_EQUIPMENT_SET_VIEW_REQUEST = "MAIN_APP/SET_EQUIPMENT_SET_VIEW_REQUEST";

export const REGISTER_USER_REQUEST = "MAIN_APP/REGISTER_USER_REQUEST";
export const REGISTER_USER_REQUEST_SUCCESS = "MAIN_APP/REGISTER_USER_REQUEST_SUCCESS";
export const REGISTER_USER_REQUEST_ERROR = "MAIN_APP/REGISTER_USER_REQUEST_ERROR";


export const M_REBATE_RECEIVE_REQUEST = "MAIN_APP/M_REBATE_RECEIVE_REQUEST";
export const M_REBATE_RECEIVE_REQUEST_SUCCESS = "MAIN_APP/M_REBATE_RECEIVE_REQUEST_SUCCESS";
export const M_REBATE_RECEIVE_REQUEST_ERROR = "MAIN_APP/M_REBATE_RECEIVE_REQUEST_ERROR";

export const BATCH_M_REBATE_PROCESS_SET_REQUEST = "MAIN_APP/BATCH_M_REBATE_PROCESS_SET_REQUEST";
export const BATCH_M_REBATE_PROCESS_SET_REQUEST_SUCCESS = "MAIN_APP/BATCH_M_REBATE_PROCESS_SET_REQUEST_SUCCESS";

export const M_REBATE_PROCESS_SET_REQUEST = "MAIN_APP/M_REBATE_PROCESS_SET_REQUEST";
export const M_REBATE_PROCESS_SET_REQUEST_SUCCESS = "MAIN_APP/M_REBATE_PROCESS_SET_REQUEST_SUCCESS";

export const M_REBATE_PROCESS_REQUEST = "MAIN_APP/M_REBATE_PROCESS_REQUEST";
export const M_REBATE_PROCESS_REQUES_SUCCESS = "MAIN_APP/M_REBATE_PROCESS_REQUES_SUCCESS";
export const M_REBATE_PROCESS_REQUES_ERROR = "MAIN_APP/M_REBATE_PROCESS_REQUES_ERROR";

export const REPORT_GET_REQUEST = "MAIN_APP/REPORT_GET_REQUEST";
export const REPORT_GET_REQUEST_SUCCESS = "MAIN_APP/REPORT_GET_REQUEST_SUCCESS";
export const REPORT_GET_REQUEST_ERROR = "MAIN_APP/REPORT_GET_REQUEST_ERROR";